<template>
  <div class="container">
    <div class="yulin_news">
      <div class="yulin_news_title">羽林新闻</div>
      <div :class="class_name">
        <div class="news_table" v-for="item in news" :key="item.A_Id">
          <div class="news_img">
            <router-link :to="{path:'/userCenter/newDetail', query:{ id:item.A_Id }}">
              <img :src="item.SmallPic" alt="">
            </router-link>
          </div>
          <div class="news_title">
            <router-link :to="{path:'/userCenter/newDetail', query:{ id:item.A_Id }}">{{item.Title}}</router-link>
          </div>
          <div class="news_time">
            <router-link :to="{path:'/userCenter/newDetail', query:{ id:item.A_Id }}">{{moment(item.CreateTime).format('YYYY/MM/DD HH:mm:ss')}}</router-link>
          </div>
        </div>
      </div>
      <div class="loading" v-if="is_loading_box">
        <Loading v-if="is_loading"></Loading>
        <p>{{is_loading ? '数据加载中...' : '数据加载失败'}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import store from '@/store'

import moment from '@/utils/moment.js'

import Loading from '../../components/loading'

export default {
  components: { Loading },
  data() {
    return {
      news: [], // 新闻列表
      is_loading_box: true, // 控制loading状态盒子是否显示
      is_loading: true, // 控制loading状态动画以及文字的显示
      moment: moment,
      class_name: {
        news_list: true,
        flex: true,
        'align-center': true,
        news_list_length: false
      }
    }
  },
  mounted() {
    axios
      .get('http://api.yulindata.net/api/Art/GetList?PageIndex=1&PageRows=3')
      .then(res => {
        if (res.data.IsSuccess) {
          this.news = res.data.Entities

          // 样式问题：justify-content: space-between;
          if (this.news.length === 2) {
            this.class_name['news_list_length'] = true
          } else {
            this.class_name['news_list_length'] = false
          }
          // 请求成功关闭loading盒子
          this.is_loading_box = false
        } else {
          // 请求失败关闭loading动画，更改loading文字
          this.is_loading = false
          window.alert(res.data.Message)
        }
      })
      .catch(error => {})
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 1200px;
  margin: 0 auto;

  .yulin_news {
    .yulin_news_title {
      height: 35px;
      font-size: 36px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #333333;
      text-align: center;
      margin-bottom: 58px;
    }
    .news_list {
      margin-bottom: 136px;
      justify-content: space-between;
    }
    .news_list_length:after {
      content: '';
      width: 300px;
    }

    .news_table {
      width: 300px;
      background: #ffffff;
      box-shadow: 0px 0px 7px 0px rgba(193, 193, 193, 0.2);
    }

    .news_img {
      width: 300px;
      height: 196px;
      /* background: #313131; */
      margin-bottom: 22px;
    }

    .news_img img {
      width: 100%;
      height: 100%;
    }

    .news_title {
      height: 35px;
      font-size: 16px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #666666;
      padding: 0 18px;
      margin-bottom: 40px;
    }

    .news_time {
      padding-left: 18px;
      font-size: 16px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #666666;
      padding-bottom: 16px;
    }

    .loading {
      display: flex;
      flex-direction: column;
      height: 325px;
      align-items: center;
      justify-content: center;
      p {
        font-size: 20px;
        margin-top: 20px;
      }
    }
  }
}

@media all and (max-width: 1080px) {
  .container {
    .yulin_news {
      .news_list {
        margin-bottom: 50px;
        & > div {
          margin: 0 auto;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>