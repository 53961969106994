<template>
    <div class="product_wrapper">
        <div class="container">
            <div class="product_introduce">
                <div class="product_title">
                    品牌介绍
                </div>
                <div class="product_des">
                    羽林数据 <br> 为企业整合营销效果而打造的数字推广中台
                </div>
                <div class="product_content">
                    羽林数据，为整合营销效果而生！为每一个企业老板打造一个数字营销中台，提升数字营销战斗力！数据驱动全场景的业务分析与决策，通过大数据筛选，推荐精准客户进行营销，有效提高推广曝光率。结合全渠道精准营销的行动与反馈，追踪客户消费习惯、智能分析客户消费能力和增购复购机会，实现数字化推广。动动手指了解每天订单业务量，轻松掌握经营情况。

                </div>
                <div class="product_second_title">
                    产品

                </div>
                <div class="product_second_des">
                    两云一中台赋能企业营销推广数字化闭环 <br> 产品理念：为效果付费，让企业赚钱！
                </div>

                <div class="product_list flex">
                    <div class="product_list_table" v-for="(item,index) in list" :key="index">
                        <img :src="require('../../../assets/'+item.img)" alt="">
                        <div>
                            <p>{{item.p}}</p>
                            <div>
                                <router-link :to="item.path">了解详情</router-link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
  // 品牌介绍
  name: 'ProductWrapper',
  data() {
    return {
      list: [
        {
          img: 'img/index/product-car.png',
          p: '羽林二类电商云',
          path: '/product/companyCloud'
        },
        {
          img: 'img/index/product-info.png',
          p: '羽林品牌企业推广云',
          path: '/product/promoteCloud'
        },
        {
          img: 'img/index/product-middle.png',
          p: '羽林数据中台',
          path: '/solution/dataCenter'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.product_wrapper {
  padding-top: 130px;
  margin-bottom: 80px;
  .product_introduce {
    padding: 64px 214px 72px;
    background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/4700ef82387702299482657428/f0.png');
    background-size: 100% 100%;
    .product_title {
      font-size: 36px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      margin-bottom: 30px;
    }
    .product_des {
      font-size: 20px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
      text-align: center;
      margin-bottom: 30px;
    }
    .product_content {
      font-size: 14px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      margin-bottom: 84px;
    }
    .product_second_title {
      font-size: 20px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 26px;
      text-align: center;
    }
    .product_second_des {
      font-size: 18px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 30px;
      margin-bottom: 40px;
    }

    .product_list {
      justify-content: space-between;
      margin: 0 auto;
      .product_list_table {
        width: 190px;
        height: 234px;
        background: #ffffff;
        box-shadow: 0px 0px 10px 0px rgba(0, 17, 122, 0.2);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        img {
          width: 90px;
          margin: 26px 0 24px;
        }
        & > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p {
            font-size: 16px;
            font-family: GraublauWeb;
            font-weight: 400;
            color: #333333;
            margin-bottom: 24px;
          }
          div {
            width: 98px;
            height: 32px;
            border: 1px solid #0043b3;
            border-radius: 6px;
            font-size: 16px;
            font-family: GraublauWeb;
            font-weight: 400;
            color: #0043b3;
            line-height: 32px;
            text-align: center;
            cursor: pointer;
            a {
              color: #0043b3;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 1080px) {
  .product_wrapper {
    padding-top: 109px;
    .product_introduce {
      padding: 56px 0 0 0;
      background-image: url(http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/46dc32ff387702299482636491/f0.png);
      .product_des {
        font-size: 18px;
      }

      .product_content {
        padding: 0 10px;
        margin-bottom: 76px;
      }

      .product_second_title {
        margin-bottom: 21px;
      }

      .product_list {
        padding-bottom: 40px;
        .product_list_table {
          width: 68%;
          height: auto;
          padding: 15px 29px;
          box-sizing: border-box;
          flex-direction: unset;
          margin: 0 auto;
          border-radius: 5px;
          margin-bottom: 45px;
          img {
            margin: auto 0;
          }
          & > div {
            align-items: unset;
            margin-left: 13px;
          }
        }
      }
    }
  }
}
</style>