<template>
    <div class="user_img_bg_wrapper">
        <div class="user_img_bg_wrapper">
            <div class="container">
                <div class="user_image_head">
                    用户画像
                </div>
                <div class="user_image_head_des">
                    数字营销时代线上电商是抢占客户市场的主流 <br> 而主流用户大概可以分为三类。
                </div>
                <div class="flex user_img_justi">
                    <div class="user_image_table" v-for="(item,index) in list" :key="index">
                        <div class="user_image_title">
                            <img :src="require('../../../assets/'+item.img)" alt="">
                            <div>
                                <p>{{item.p1}}</p>
                                <p>{{item.p2}}</p>
                            </div>
                        </div>
                        <div class="user_image_content">
                            {{item.content}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  // 用户画像
  name: 'UserImg',
  data() {
    return {
      list: [
        {
          img: 'img/index/pingpai.png',
          p1: '品牌企业',
          p2: '品牌企业电商“不上不下”',
          content:
            '十分注重线上营销，营销渠道广，在营销广告上也毫不吝啬，但往往收效甚微，加上品牌竞争激烈，做出效果的少之又少，于是造成电商营销资源浪费，品牌效益“不上不下”的局面。'
        },
        {
          img: 'img/index/dianshang.png',
          p1: '二类电商',
          p2: '二类电商被某多多截杀',
          content:
            '曾经凭借固定粉丝、货到付款等优势赢取客户的优势都已被替代，更加便宜、具有价格优势的某多多收割了更多客户。营销渠道少，且需要付出大量成本，盈利微薄，客户群体缩减。'
        },
        {
          img: 'img/index/zhibo.png',
          p1: '直播营销类电商',
          p2: '直播电商被头部玩家占领',
          content:
            '这类电商的特点是规模不一定大，以内容赢取流量逐渐带货。前期拍视频、创作及打造网红的时间精力成本投入相对高，吸引流量多少存在不确定性，流量变现率更加不确定，真正的流量红利还是掌握在头部玩家手里。'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.user_img_bg_wrapper {
  padding-top: 20px;
  padding-bottom: 106px;
  .user_image_head {
    text-align: center;
    font-size: 36px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #333333;
    margin-bottom: 40px;
  }

  .user_image_head_des {
    font-size: 18px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #333333;
    text-align: center;
    margin-bottom: 58px;
    line-height: 30px;
  }
  .user_img_justi {
    justify-content: space-between;
    .user_image_table {
      width: 360px;
      border: 1px solid #e6ebfe;
      height: 250px;
      .user_image_title {
        display: flex;
        align-items: center;
        padding: 26px 0px 22px 20px;
        img {
          width: 42px;
          height: 42px;
          margin-right: 27px;
        }
        & > div > p:nth-child(1) {
          font-size: 18px;
          font-family: GraublauWeb;
          font-weight: 400;
          color: #333333;
          margin-bottom: 10px;
        }
        & > div > p:nth-child(2) {
          font-size: 14px;
          font-family: GraublauWeb;
          font-weight: 400;
          color: #333333;
        }
      }
      .user_image_content {
        padding: 24px 16px 0px;
        background: #f3f6ff;
        height: 136px;
        font-size: 14px;
        font-family: GraublauWeb;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
      }
    }
  }
}

@media all and (max-width: 1080px) {
  .user_img_bg_wrapper {
    .user_image_head {
      margin-bottom: 26px;
    }
    .user_image_head_des {
      font-size: 18px;
      margin-bottom: 40px;
    }
    .user_image_table {
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }
}
</style>