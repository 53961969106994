<template>
  <div @mouseover="enter" @mouseout="leave">
    <div class="swiper_wrapper">
      <div class="swiper-container swiper-container-0" id="swiper-container-0" ref="swiperContainer0">
        <div class="swiper-wrapper">
          <div class="swiper-slide">

            <div class="swiper_1">
              <div class="container swiper_1_container">
                <div class="flex align_center swiper_1_wrapper">
                  <!-- <div class="swiper_1_wrapper_left">
                    <div>两云一中台</div>
                    <div> 赋能企业营销推广数字化闭环</div>
                    <div class="swiper_1_wrapper_left_list">
                      <div>羽林品牌企业推广云</div>
                      <div>+</div>
                      <div>羽林二类电商云</div>
                      <div>+</div>
                      <div>羽林数据中台</div>

                    </div>
                  </div>
                  <div class="swiper_1_wrapper_right">

                  </div> -->
                </div>
              </div>

            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper_2_wrapper">
              <div class="container swiper_1_container">
                <div style="height: 100%;" class="flex align_center ">
                  <!-- <div class="swiper_2_wrapper_left">
                    <div class="swiper_2_wrapper_left_heaed">
                      为效果付费，让企业赚钱
                    </div>
                    <div class="swiper_2_wrapper_left_heaed_des">
                      降低 <img src="../../../assets/img/index/down.png" alt=""> 推广成本+提高 <img src="../../../assets/img/index/rise.png" alt=""> 决策效率+增加 <img src="../../../assets/img/index/top-arrow.png" alt=""> 订单量
                    </div>
                    <div class="swiper_2_mid_line">

                    </div>
                    <div class="swiper_2_mid_content">
                      <div>羽林数据，用订单说话！
                      </div>
                      <div> 欢迎迈入为效果付费的数字营销时代！</div>
                    </div>
                  </div>
                  <div class="swiper_2_wrapper_right">
                    <img src="../../../assets/img/index/banner-img.png" alt="">
                  </div> -->
                    <!-- <img src="../../../assets/img/index/2.png" alt=""> -->
                </div>

              </div>

            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper_3_wrapper">
              <div class="flex align-center">
                <!-- <div class="swiper_3_left">
                  <div class="swiper_3_wrapper_head">羽林数据</div>
                  <div class="swiper_3_wrapper_des_1">为整合营销效果而生
                  </div>
                  <div class="swiper_3_wrapper_des_1 swiper_3_wrapper_des_margin">提升数字推广战斗力</div>
                  <div class="swiper_3_wrapper_bottom">
                    <div>数字化推广</div>
                    <div>+</div>
                    <div>ROI智能评估 </div>
                    <div>+</div>
                    <div>数据算法获客</div>

                  </div>
                </div>
                <div class="swiper_3_right">
                  <img src="../../../assets/img/index/ws.png" alt="">
                </div> -->
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <!-- <img class="banner_4_more" src="../../../assets/img/index/banner4.jpg" alt=""> -->
            <img class="banner_4_more" src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/42681917387702299482466005/f0.png" alt="">
            <img class="banner_4_mini" src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/8aa79a60387702299483259256/f0.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from '../../../utils/swiper'

export default {
  // 顶部轮播
  name: 'TopSwiper',
  data() {
    return {
      swiper: null
    }
  },
  created() {},
  mounted() {
    this.swiper = new Swiper(this.$refs.swiperContainer0, {
      autoplay: true
    })
  },
  methods: {
    enter() {
      this.swiper.autoplay.stop()
    },
    leave() {
      this.swiper.autoplay.run()
    }
  }
}
</script>

<style lang="less" scoped>
.swiper-slide {
  width: 100%;
  height: 100%;
}
.swiper_wrapper {
  width: 100%;
  height: 700px;
  margin-bottom: 130px;
  .swiper_1 {
    width: 100%;
    height: 100%;
    // background-image: url('../../../assets/img/index/banner-1.png');
    background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/014d8346387702299481995770/f0.png');
    background-size: cover;
    // .swiper_1_container {
    //   height: 100%;
    //   .swiper_1_wrapper {
    //     /* justify-content: center; */
    //     /* padding-left: 20%; */
    //     height: 100%;
    //     .swiper_1_wrapper_left {
    //       & > div:nth-child(1) {
    //         font-size: 80px;
    //         font-family: 'zihun';
    //         font-weight: 400;
    //         color: #ffffff;
    //         text-shadow: 0px 4px 6px rgba(0, 11, 124, 0.63);
    //         margin-bottom: 44px;
    //         margin-left: -16px;
    //       }
    //       & > div:nth-child(2) {
    //         font-size: 30px;
    //         font-family: PingFang SC;
    //         font-weight: 300;
    //         color: rgba(255, 255, 255, 0.7);
    //         margin-bottom: 50px;
    //       }
    //       .swiper_1_wrapper_left_list {
    //         display: flex;
    //         align-items: center;
    //         & > div:nth-of-type(odd) {
    //           border: 1px solid #ffffff;
    //           border-radius: 5px;
    //           font-size: 16px;
    //           font-family: PingFang SC;
    //           font-weight: 400;
    //           color: rgba(255, 255, 255, 0.7);
    //           padding: 2px 4px;
    //         }
    //         & > div:nth-of-type(even) {
    //           font-size: 22px;
    //           font-family: PingFang SC;
    //           font-weight: 400;
    //           color: rgba(255, 255, 255, 0.7);
    //           margin: 0 4px;
    //         }
    //       }
    //     }
    //     .swiper_1_wrapper_right {
    //       width: 60%;
    //       position: absolute;
    //       right: -20px;
    //       img {
    //         width: 100%;
    //       }
    //     }
    //   }
    // }
  }
  .swiper_2_wrapper {
    width: 100%;
    height: 100%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // background-image: url('../../../assets/img/index/banner1.png');
    background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/f2bc0830387702299481312045/f0.png');
    background-size: cover;
    // .swiper_2_wrapper_left {
    //   margin-right: 24px;
    //   .swiper_2_wrapper_left_heaed {
    //     font-size: 48px;
    //     font-family: GraublauWeb;
    //     font-weight: 400;
    //     color: #ffffff;
    //     text-shadow: 0px 3px 4px rgba(0, 44, 94, 0.2);
    //     margin-bottom: 34px;
    //   }
    //   .swiper_2_wrapper_left_heaed_des {
    //     font-size: 28px;
    //     font-family: GraublauWeb;
    //     font-weight: 300;
    //     color: rgba(255, 255, 255, 0.7);
    //     text-shadow: 0px 3px 4px rgba(0, 44, 94, 0.2);
    //     margin-bottom: 50px;
    //   }
    //   .swiper_2_mid_line {
    //     width: 90px;
    //     height: 4px;
    //     background: #ffffff;
    //     margin-bottom: 44px;
    //   }
    //   .swiper_2_mid_content {
    //     font-size: 24px;
    //     font-family: GraublauWeb;
    //     font-weight: 300;
    //     color: rgba(255, 255, 255, 0.7);
    //     text-shadow: 0px 3px 4px rgba(6, 0, 255, 0.2);
    //     & > div:nth-child(1) {
    //       margin-bottom: 20px;
    //     }
    //   }
    // }
    // .swiper_2_wrapper_right {
    //   width: 46%;
    //   img {
    //     width: 100%;
    //   }
    // }
  }

  .swiper_3_wrapper {
    width: 100%;
    height: 100%;
    // background-image: url('../../../assets/img/index/banner2.png');
    background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/014dfa49387702299481996268/f0.png');
    background-size: cover;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // .swiper_3_wrapper_head {
    //   font-size: 110px;
    //   font-family: 'zihun';
    //   font-weight: 500;
    //   color: #ffffff;
    //   text-align: center;
    //   margin-bottom: 40px;
    //   margin-left: -58px;
    // }
    // .swiper_3_wrapper_des_1 {
    //   font-size: 36px;
    //   font-family: PingFang SC;
    //   font-weight: 500;
    //   color: #deeefc;
    //   line-height: 48px;
    //   text-shadow: 0px 3px 8px rgba(0, 61, 213, 0.4);
    //   margin-bottom: 10px;
    // }
    // .swiper_3_wrapper_des_margin {
    //   margin-bottom: 70px;
    // }
    // .swiper_3_wrapper_bottom {
    //   display: flex;
    //   align-items: center;
    //   & > div:nth-of-type(odd) {
    //     background: #ffffff;
    //     box-shadow: 0px 2px 10px 0px rgba(3, 54, 170, 0.4);
    //     border-radius: 20px;
    //     font-size: 18px;
    //     font-family: PingFang SC;
    //     font-weight: 500;
    //     color: #003dd5;
    //     padding: 4px 12px;
    //   }
    //   & > div:nth-of-type(even) {
    //     font-size: 36px;
    //     font-family: PingFang SC;
    //     font-weight: 300;
    //     color: #ffffff;
    //     margin: 0 10px;
    //   }
    // }
    // .swiper_3_right {
    //   margin-left: 90px;
    //   img {
    //     height: 500px;
    //   }
    // }
  }

  .banner_4_mini {
    display: none;
  }
}

@media all and (max-width: 1080px) {
  .flex {
    display: block;
  }
  .swiper_wrapper {
    height: 400px;
    margin-bottom: 0;
    .swiper_1 {
      background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/9194f2de387702299483549027/f0.png');
      background-size: 100% 100%;
      // .swiper_1_wrapper {
      //   display: flex;
      //   align-items: flex-start;
      //   .swiper_1_wrapper_left {
      //     margin: 0 auto;
      //     & > div {
      //       text-align: center;
      //       &:nth-child(1) {
      //         font-size: 40px !important;
      //         padding-top: 20px;
      //       }
      //       &:nth-child(2) {
      //         font-size: 20px !important;
      //       }
      //     }
      //   }
      //   .swiper_1_wrapper_left_list {
      //     justify-content: center;
      //     & > div:nth-of-type(odd) {
      //       font-size: 12px !important;
      //     }
      //   }
      // }

      // .swiper_1_wrapper_right {
      //   width: 400px;
      //   position: relative;
      //   margin: 0 auto;
      //   right: 0;
      // }
    }
    .swiper_2_wrapper {
      background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/91a69d25387702299483558754/f0.png');
      background-size: 100% 100%;
      // .swiper_2_wrapper_left {
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: center;
      //   align-items: center;
      //   margin-right: 0;
      //   .swiper_2_wrapper_left_heaed {
      //     font-size: 30px;
      //     padding-top: 20px;
      //   }
      //   .swiper_2_wrapper_left_heaed_des {
      //     font-size: 16px;
      //     & > img {
      //       width: 10px;
      //     }
      //   }
      //   .swiper_2_mid_content {
      //     font-size: 18px;
      //     margin-bottom: 20px;
      //   }
      // }
      // .swiper_2_wrapper_right {
      //   margin: 0 auto;
      //   width: 80%;
      // }
    }
    .swiper_3_wrapper {
      background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/964fbbc0387702299483756128/f0.png');
      background-size: 100% 100%;
      // .swiper_3_left {
      //   display: flex;
      //   flex-direction: column;
      //   align-items: center;
      //   justify-content: center;
      // }

      // .swiper_3_right {
      //   margin: 0;
      //   img {
      //     width: 100%;
      //     height: 200px;
      //   }
      // }

      // .swiper_3_wrapper_head {
      //   font-size: 50px;
      //   margin-left: 0;
      //   margin-bottom: 20px;
      // }

      // .swiper_3_wrapper_des_1 {
      //   font-size: 24px;
      //   text-align: center;
      // }

      // .swiper_3_wrapper_des_margin {
      //   margin-bottom: 20px;
      // }

      // .swiper_3_wrapper_bottom {
      //   margin-bottom: 20px;
      //   & > div:nth-of-type(odd) {
      //     font-size: 14px;
      //   }
      //   & > div:nth-of-type(even) {
      //     font-size: 20px;
      //     margin: 0 5px;
      //   }
      // }
    }

    .banner_4_mini {
      width: 100%;
      height: 400px;
      display: inline-block;
    }

    .banner_4_more {
      display: none;
    }
  }
}
</style>