<template>
    <div class="full_link">
        <div class="container">
            <div class="link_title">羽林数据中台完成从获客、运营到提高利润的全链路支撑</div>
            <div class="link_title_mini">
                <p>羽林数据中台</p>
                <p>完成从获客、运营到提高利润的全链路支撑</p>
            </div>
            <div class="link_list">

                <div class="link_table" v-for="(item,index) in list" :key="index">
                    <p>{{item.p}}</p>
                    <img :src="require('../../../assets/'+item.img)" alt="">
                    <div>
                        {{item.text1}} <br v-show="item.text2"> {{item.text2 ? item.text2 : ''}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  // 数据中台
  name: 'DataCenter',
  data() {
    return {
      list: [
        {
          p: '获客、固客方便',
          img: 'img/index/huokefangbian.png',
          text1: 'H5智能商城',
          text2: '（整合营销推广数字化）'
        },
        {
          p: '成交更容易',
          img: 'img/index/roizhinneg.png',
          text1: 'ROI智能推荐'
        },
        {
          p: '运营更简单',
          img: 'img/index/suanfashuju.png',
          text1: '数据算法'
        },
        {
          p: '管理更简洁',
          img: 'img/index/jingyingkanban.png',
          text1: '经营看板'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.full_link {
  padding: 64px 0px 54px 0px;
  background: #f0f5fb;
  margin-bottom: 136px;
  .link_title {
    font-size: 24px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #333333;
    text-align: center;
    margin-bottom: 64px;
  }

  .link_title_mini {
    display: none;
  }

  .link_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .link_table {
      width: 260px;
      height: 196px;
      background: #ffffff;
      box-shadow: 0px 1px 8px 0px rgba(0, 48, 101, 0.1);
      border-radius: 10px;
      padding-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      p {
        font-size: 24px;
        font-family: GraublauWeb;
        font-weight: 400;
        color: #333333;
        margin-bottom: 24px;
      }
      img {
        width: 69px;
        margin-bottom: 22px;
      }
      div {
        height: 50px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: GraublauWeb;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        text-align: center;
        margin-bottom: 14px;
      }
    }
  }
}

@media all and (max-width: 1080px) {
  .full_link {
    .container {
      .link_title {
        display: none;
      }

      .link_title_mini {
        display: block;
        text-align: center;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 40px;
      }

      .link_list {
        display: block;
            .link_table {
        margin: 0 auto;
        margin-bottom: 20px;
    }
    }
    }
  }
}
</style>