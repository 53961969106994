<template>
  <div class="solution_wrapper">
    <div class="container">
      <div class="solution_bg">
        <div class="solution_title">解决方案</div>
        <div class="solutuon_des">羽林数据中台为企业电商打造的数字推广平台</div>
        <div class="solution_conetent">
          构建H5智能商城，精细化前端一手掌握客户数据，品牌推广、客户管理、订单完成清晰条理。 ROI实时分析客户行为，不同的数据算法实时推荐最优数据，不再用心算，不靠感觉做决策。 从数字推广到ROI评估，数据算法推荐形成完美运营方案。

        </div>
        <div class="flex align-center solution_table">
          <div v-for="(item,index) in list1" :key="index">
            <img :src="require('../../../assets/'+item.img)" alt=""> {{item.text1}} <br v-show="item.text2"> {{item.text2 ? item.text2 : ''}}
          </div>
        </div>
      </div>
      <div class="three_points">
        <div class="three_points_title">三大核心利器解决企业运营的难题
        </div>
        <div class="three_points_des">
          电商运营是一个数字化推广和运营的过程，由于运营过程的冗长繁杂，不能把握核心往往容易沦为打杂， <br> 羽林数据中台围绕提升“数字运营战力”亮出三大利器，为企业运营开拓局面。

        </div>
        <div class="three_points_list">
          <div class="three_p_table" v-for="(item,index) in list2" :key="index">
            <img :src="require('../../../assets/'+item.img)" alt="">
            <div>
              <p>{{item.p}}</p>
              <div class="thress_mid_line"></div>
              <div>{{item.div}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 解决方案
  name: 'SolutionWrapper',
  data() {
    return {
      list1: [
        {
          img: 'img/index/online_trade.png',
          text1: '赋能成交'
        },
        {
          img: 'img/index/has_pay.png',
          text1: '智能交易系统'
        },
        {
          img: 'img/index/get_user.png',
          text1: '锁客系统'
        },
        {
          img: 'img/index/tuiguang.png',
          text1: '直播带货、',
          text2: '推广一气完成'
        }
      ],
      list2: [
        {
          img: 'img/index/zhineng.png',
          p: '利器一：智能推广',
          div: '以短信推广为主的整合营销模式， 一手客源在握，不为没客户发愁'
        },
        {
          img: 'img/index/shujusunafa.png',
          p: '利器二：数据算法',
          div: '数据算法跟进用户行为，分析订单属性， 不再忧心转化率'
        },
        {
          img: 'img/index/roituijian.png',
          p: '利器三：ROI推荐',
          div: 'ROI推荐最优数据，商品运营状态一目了然， 不愁经营方向和思路'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.solution_wrapper {
  .solution_bg {
    height: 374px;
    background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/4252bf73387702299482450251/f0.png');
    background-size: 100% 100%;
    padding: 76px 72px 0px;
    margin-bottom: 120px;
    .solution_title {
      text-align: center;
      font-size: 36px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 50px;
    }

    .solutuon_des {
      font-size: 18px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      margin-bottom: 36px;
    }

    .solution_conetent {
      font-size: 14px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
      width: 570px;
      margin: 0 auto;
      margin-bottom: 70px;
    }

    .solution_table {
      justify-content: space-between;
      div {
        padding: 24px 0px 24px 26px;
        width: 191px;
        height: 50px;
        background: #ffffff;
        border-radius: 6px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: GraublauWeb;
        font-weight: 400;
        color: #1740df;
        line-height: 30px;
        box-shadow: 0px 1px 8px 0px rgba(0, 48, 101, 0.1);
        img {
          width: 46px;
          margin-right: 20px;
        }
      }
    }
  }
  .three_points {
    .three_points_title {
      font-size: 18px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #333333;
      margin-bottom: 40px;
      text-align: center;
    }

    .three_points_des {
      width: 646px;
      margin: 0 auto;
      font-size: 14px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      margin-bottom: 52px;
    }

    .three_points_list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 100px;
      margin-bottom: 80px;
      .three_p_table {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 210px;
        img {
          width: 134px;
          margin-bottom: 22px;
        }
        p {
          font-size: 18px;
          font-family: GraublauWeb;
          font-weight: 400;
          color: #333333;
          margin-bottom: 12px;
          text-align: center;
        }
        .thress_mid_line {
          /* width: 74px; */
          height: 2px;
          background: linear-gradient(180deg, #163bfa, #317bff);
          margin-bottom: 18px;
        }
        div {
          div {
            font-size: 14px;
            font-family: GraublauWeb;
            font-weight: 400;
            color: #666666;
            line-height: 22px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1080px) {
  .solution_wrapper {
    .container {
      .solution_bg {
        height: auto;
        padding: 74px 20px 0px;
        .solution_title {
          margin-bottom: 44px;
        }
        .solution_conetent {
          width: 100%;
          font-size: 18px;
        }
        .solution_table {
          display: flex !important;
          flex-wrap: wrap;
          div {
            /* margin: 0 auto; */
            width: 43%;
            line-height: 18px;
            font-size: 15px;
            padding: 10px;
            // font-size: 0.9rem;
            margin-bottom: 20px;
          }
        }
      }

      .three_points {
        .three_points_des {
          width: auto;
          padding: 0 10px;
        }
        .three_points_list {
          flex-direction: column;
          padding: 0;
          .three_p_table {
            width: 90%;
            box-sizing: border-box;
            flex-direction: unset;
            box-shadow: 0px 1px 8px 0px rgb(0 48 101 / 10%);
            padding: 20px 25px;
            margin-bottom: 40px;
            &:last-child {
              margin-bottom: 0;
            }
            & > div {
              p {
                text-align: left;
              }
            }
            img {
              width: 100px;
              margin-right: 25px;
              margin-bottom: 0;
            }
            .thress_mid_line {
              width: 45px;
            }
          }
        }
      }
    }
  }
}
</style>