<template>
    <div class="user_insight_wrapper">
        <div class="container">
            <div class="user_insight_title">
                用户洞察
            </div>
            <div class="user_insight_des">
                品牌企业电商运营痛点
            </div>
            <div class="user_insight_list flex">
                <div class="click_change_line" v-for="(item,index) in list" :key="index" @click="switch_swiper(index)">{{item}}</div>
                <div class="slider_line" ref="sliderLine"></div>
            </div>

            <div class="second_slide">
                <div class="swiper-container-1 swiper-container " ref="swiperContainer1">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide slide-1-main">
                            <div class="swiper_tuiguang">
                                <div class="flex align-items">
                                    <div class="tuiguang_left-1">
                                        <div class="tuiguang_left_content">电商平台开启第一步的关键就是数据基础， 其次就是选择何种方式精准获客。
                                        </div>
                                    </div>
                                    <div class="tuiguang_right">
                                        <div class="right_top_line"></div>
                                        <div class="t_right_1">
                                            1.无数据基础，手上没有有效数据，获客难；
                                        </div>
                                        <div class="t_right_1">
                                            2.推广渠道多，很难选出流量集中的推广平台，短视频成本高，易被平台绑架；
                                        </div>
                                        <div class="t_right_1">
                                            3.推广效果差，广告投入大，产出少，拓新手段单一，依赖于低价或促销活动；
                                        </div>
                                        <div class="t_right_1">
                                            4.各渠道引流线索繁杂，很难集中力量打胜仗，老客户很难去激活。
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide slide-1-main">
                            <div class="swiper_tuiguang">
                                <div class="flex align-items">
                                    <div class="tuiguang_left-2">
                                        <div class="tuiguang_left_content">
                                            客户是否会在平台下单、客服能否及时回复把握商机、客户的消费层级、订单的真实性都影响着转化与成交的结果，如何把握这些信息又是一大难题。

                                        </div>
                                    </div>
                                    <div class="tuiguang_right">
                                        <div class="right_top_line"></div>
                                        <div class="t_right_1">
                                            1.企业品牌电商平台为节省成本一般会做的比较简洁，用户出于消费习惯会选择相对信任度较高的平台下单，很难起量；

                                        </div>
                                        <div class="t_right_1">
                                            2.客服系统不够完备，不能够及时回复，容易错过商机；

                                        </div>
                                        <div class="t_right_1">
                                            3.没有客户行为分析，简短的客户沟通，很难判定客户意向；

                                        </div>
                                        <div class="t_right_1">
                                            4.有货到付款服务，虚假订单或者退单消耗利润。
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide slide-1-main">
                            <div class="swiper_tuiguang">
                                <div class="flex align-items">
                                    <div class="tuiguang_left-3">
                                        <div class="tuiguang_left_content">
                                            推广方式决定流量来源，订单、库存、毛利是营销的推动力，ROI是电商运营的根基，繁杂的分配与跟进，如果都是估算，会经常漏洞百出。

                                        </div>
                                    </div>
                                    <div class="tuiguang_right">
                                        <div class="right_top_line"></div>
                                        <div class="t_right_1">
                                            1.多渠道推广，内容运营复杂，同一内容多平台做变化，耗费大量精力；

                                        </div>
                                        <div class="t_right_1">
                                            2.订单、库存、物流不在同一系统，很难快速统一经营状况；

                                        </div>
                                        <div class="t_right_1">
                                            3.数据跟进靠人力，时效差，每天的经营与成本都不能及时掌握；

                                        </div>
                                        <div class="t_right_1">
                                            4.数据的深度分析困难，很难跟进实际数据去做判断，推广决策全靠拍脑袋。
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide slide-1-main">
                            <div class="swiper_tuiguang">
                                <div class="flex align-items">
                                    <div class="tuiguang_left-4">
                                        <div class="tuiguang_left_content">
                                            客户、销售和业绩管理都是毛利增长转化的关键，精确的客户分析、简洁的销售管理、精准的业务分析是业务不断增进的关键。

                                        </div>
                                    </div>
                                    <div class="tuiguang_right">
                                        <div class="right_top_line"></div>
                                        <div class="t_right_1">
                                            1.客户管理难，不同时期、不同渠道的客户需求不同，没有客户的行为纪录和分类标签，不了解客户的需求及增购、复购情况，很难对症下药；

                                        </div>
                                        <div class="t_right_1">
                                            2.销售管理难，客户订单分配不及时，不同商机线索难以把握，就间接造成销售工作的分配不均衡，业绩管理变复杂；

                                        </div>
                                        <div class="t_right_1">
                                            3.业绩管理难，每天的经营、成本、毛利难以及时掌握，业绩管理困难，第二天的推广多靠感觉做决策。
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from '../../../utils/swiper'
export default {
  // 用户洞察
  name: 'UserInsightWrapper',
  data() {
    return {
      swiper: null,
      activeIndex: 0,
      list: ['推广难、获客/固客难', '成交难', '运营难', '管理复杂']
    }
  },
  mounted() {
    let _this = this
    this.swiper = new Swiper(this.$refs.swiperContainer1, {
      on: {
        slideChange: function() {
          _this.$refs.sliderLine.style.left = this.activeIndex * 25 + '%'
        }
      }
    })
  },
  methods: {
    enter() {
      this.swiper.autoplay.stop()
    },
    leave() {
      this.swiper.autoplay.run()
    },
    switch_swiper(index) {
      this.swiper.slideTo(index, 1000, false)
    }
  }
}
</script>

<style lang="less" scoped>
.user_insight_wrapper {
  padding: 55px 0px 74px;
  background: #f0f5fb;
  .user_insight_title {
    font-size: 36px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #333333;
    text-align: center;
    margin-bottom: 30px;
  }
  .user_insight_des {
    font-size: 18px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #333333;
    text-align: center;
    margin-bottom: 46px;
  }
  .user_insight_list {
    align-items: center;
    padding-bottom: 18px;
    position: relative;
    margin-bottom: 36px;
    & > div {
      flex: 1;
      text-align: center;
      cursor: pointer;
      font-size: 18px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #333333;
    }
    .slider_line {
      position: absolute;
      width: 300px;
      height: 2px;
      background: #1b47fb;
      left: 0;
      bottom: -2px;
      transition: all 0.5s;
    }
  }

  .slide-1-main {
    height: 370px;
    .tuiguang_left-1 {
      width: 50%;
      background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/4220f2e0387702299482428880/f0.png');
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      height: 370px;
    }
    .tuiguang_left-2 {
      width: 50%;
      background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/42217abe387702299482429760/f0.png');
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      height: 370px;
    }

    .tuiguang_left-3 {
      width: 50%;
      background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/42218325387702299482429956/f0.png');
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      height: 370px;
    }

    .tuiguang_left-4 {
      width: 50%;
      background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/4269dc04387702299482469708/f0.png');
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      height: 370px;
    }

    .tuiguang_left_content {
      padding: 36px 34px;
      font-size: 18px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
      background-color: rgba(49, 123, 255, 0.9);
      box-shadow: 0px 1px 8px 0px rgba(0, 48, 101, 0.2);
    }
    .tuiguang_right {
      width: calc(50% - 154px);
      background: linear-gradient(150deg, #317bff, #1740df);
      box-shadow: 0px 1px 8px 0px rgba(0, 48, 101, 0.2);
      padding: 60px 114px 0px 40px;
      .right_top_line {
        width: 98px;
        height: 2px;
        background: linear-gradient(-90deg, transparent, #ffffff);
        margin-bottom: 38px;
      }
      .t_right_1 {
        font-size: 14px;
        font-family: GraublauWeb;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
        margin-bottom: 20px;
      }
    }
  }
}

@media all and (max-width: 1080px) {
  .user_insight_wrapper {
    padding: 50px 0 74px;
    .user_insight_des {
      margin-bottom: 20px;
    }
    .user_insight_list {
      display: flex !important;
      flex-wrap: wrap;
      div {
        font-size: 14px;
      }
      .slider_line {
        width: 25vw;
      }
    }
    .second_slide {
      .slide-1-main {
        height: auto;
        .tuiguang_left-1 {
          width: 100%;
          background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/4220f2e0387702299482428880/f0.png');
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          height: 340px;
        }
        .tuiguang_left-2 {
          width: 100%;
          background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/42217abe387702299482429760/f0.png');
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          height: 340px;
        }

        .tuiguang_left-3 {
          width: 100%;
          background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/42218325387702299482429956/f0.png');
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          height: 340px;
        }

        .tuiguang_left-4 {
          width: 100%;
          background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/4269dc04387702299482469708/f0.png');
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          height: 340px;
        }
        .tuiguang_left_content {
          padding: 32px 20px 36px;
        }
        .tuiguang_right {
          width: auto;
          font-size: 24px;
          padding: 64px 47px 52px 41px;
          height: 220px;
          .right_top_line {
            display: none;
            .t_right_1 {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
</style>