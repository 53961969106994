<template>
  <div class="container guanyu">
    <div class="about_us_bg">
      <div class="about_us_title">
        关于我们
      </div>
      <div class="about_us_des">
        &emsp;&emsp; 成都酒搭网络科技有限公司（简称酒搭）成立于2016年，是新商业软件时代极具影响力的营销数字推广SaaS服务商。致力于帮助企业建立数字化营销推广中台，为品牌企业、二类电商等电商客户提供整合营销数字化解决方案，提高营销效益。为整合营销效果而生！
      </div>
    </div>
    <div class="about_us_list flex align-center">
      <div v-for="(item,index) in list" :key="index">
        <img :src="'http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/' + item.img" alt="">
        <div>
          <h2>{{item.h2}}</h2>
          <p>{{item.p}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 关于我们
  name: "AboutUs",
  data() {
    return {
      list: [
        {
          img: "f2bcae34387702299481313651/f0.png",
          h2: "愿景",
          p: "云时代营销数字化服务领导者",
        },
        {
          img: "4268aa3f387702299482467147/f0.png",
          h2: "使命",
          p: "创新营销软件 成就客户价值",
        },
        {
          img: "f2bc9873387702299481313119/f0.png",
          h2: "公司定位",
          p: "为效果付费的营销推广SaaS服务商",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.guanyu {
  .about_us_bg {
    height: 250px;
    box-sizing: border-box;
    background: linear-gradient(150deg, #317bff, #1740df);
    padding: 43px 0px 94px;
    margin-bottom: 70px;
    .about_us_title {
      font-size: 36px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 40px;
      text-align: center;
    }

    .about_us_des {
      font-size: 16px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
      width: 743px;
      margin: 0 auto;
    }
  }
  .about_us_list {
    justify-content: space-between;
    margin-bottom: 160px;
    & > div {
      font-size: 0;
      img {
        width: 280px;
      }
      div {
        height: 65px;
        background: #1858c3;
        padding: 15px 0;
        h2 {
          font-size: 26px;
          font-family: GraublauWeb;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 36px;
        }
        p {
          font-size: 18px;
          font-family: GraublauWeb;
          font-weight: 400;
          color: #ffffff;
          line-height: 36px;
          text-align: center;
        }
      }
    }
  }
}

@media all and (max-width: 1080px) {
  .guanyu {
    .about_us_bg {
      padding: 20px 10px;
      box-sizing: content-box;
      .about_us_des {
        width: auto;
        height: auto;
      }
    }
    .about_us_list {
      padding: 0 10px;
      & > div {
        margin-bottom: 10px;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
