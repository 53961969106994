<template>
  <div class="home">
    <!-- 顶部轮播 -->
    <TopSwiper></TopSwiper>

    <!-- 用户画像 -->
    <UserImg></UserImg>

    <!-- 用户洞察 -->
    <UserInsightWrapper></UserInsightWrapper>

    <!-- 品牌介绍 -->
    <ProductWrapper></ProductWrapper>

    <!-- 解决方案 -->
    <SolutionWrapper></SolutionWrapper>

    <!-- 数据中台 -->
    <DataCenter></DataCenter>

    <!-- 关于我们 -->
    <AboutUs></AboutUs>

    <!-- 羽林新闻 -->
    <!-- <YulinNews></YulinNews> -->

    <!-- 右侧悬浮框 -->
    <RightSuspension></RightSuspension>
  </div>
</template>

<script>
// 顶部轮播
import TopSwiper from "./components/top_swiper_wrapper";
// 用户画像
import UserImg from "./components/user_img_bg";
// 用户洞察
import UserInsightWrapper from "./components/user_insight_wrapper";
// 品牌介绍
import ProductWrapper from "./components/product_wrapper";
// 解决方案
import SolutionWrapper from "./components/solution_wrapper";
// 数据中台
import DataCenter from "./components/data_center";
// 关于我们
import AboutUs from "./components/about_us";
// 羽林新闻
import YulinNews from "./components/yulin_news";
// 右侧悬浮框
import RightSuspension from "@/components/right_suspension";
export default {
  components: {
    TopSwiper,
    UserImg,
    UserInsightWrapper,
    ProductWrapper,
    SolutionWrapper,
    DataCenter,
    AboutUs,
    YulinNews,
    RightSuspension,
  },
};
</script>
<style src='../../assets/css/swiper.css'></style>

<style lang="less" scoped>
</style>